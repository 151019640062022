<template>
  <div>
    <!-- <el-row :gutter="20">
      <el-col :span="2">
        <span>类型:</span>
      </el-col>
      <el-col :span="20">
        <div style="">
          <ul>
            <li style="float: left; padding: 5px 10px" v-for="i in 50" :key="i">
              <span>类型{{ i }}</span>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row> -->

    <!-- <div class="box">
      <div class="box_header">
        <div>
          <i class="el-icon-edit"></i>
          <el-link style="margin-left: 10px; font-size: 16px"
            >积极向上的正能量励志文章</el-link
          >
        </div>
        <div>质量<span class="start">5</span>分</div>
      </div>
      <div class="box_body">
        <div class="box_body_image">
          <el-image
            style="width: 120px; height: 150px"
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
          ></el-image>
        </div>
        <div class="box_body_content">
          <div>上半部分文章内容</div>
          <div>
            <div>中断时间内容</div>
            <div>下段VIP图片内容</div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="content_box">欢迎您,使用SnowyiOA系统</div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.content_box {
  width: 100%;
  height: 100%;
  font-size: 50px;
  text-align: center;
  padding-top: 50px;
  box-sizing: border-box;
  // background-color: white;
}

.box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  height: 220px;
  background: burlywood;
}
.box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.start {
  font-size: 20px;
  color: orange;
  padding: 0 5px;
}
.box_body {
  display: flex;
  height: 150px;
  background-color: aquamarine;
}
.box_body_image {
  height: 150px;
  width: 120px;
  background: red;
  flex-grow: 0;
}
.box_body_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  flex-grow: 1;
}
</style>
